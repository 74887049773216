<template>
    <div class="home-con">
      <van-nav-bar
        :title="$route.meta.title"
        left-text="完成"
        left-arrow
        @click-left="onClickLeft"
      />
      <van-cell-group :title="title" title-class="cell-title">
      <van-cell title="快捷复制">
          <!-- 使用 right-icon 插槽来自定义右侧图标 -->
          <template #right-icon>
            <van-button type="info" size="small" @click="copy" style="margin-right:10px">复制全部</van-button>
            <van-button type="info" size="small" @click="copyCode">只复制激活码</van-button>
          </template>
      </van-cell>
      <van-field
        id="inputcode"
        v-model="codes"
        rows="10"
        autosize
        type="textarea"
        placeholder="已替换的激活码"
      />
      </van-cell-group>
      <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o">
      点复制全部可以快速复制已经生成的激活码，或长按生成的激活码手动复制
      </van-notice-bar>
      <div style="margin: 16px;">
          <van-button round block plain type="info" @click="handleLog">换卡日志</van-button>
      </div> 
    </div>
</template>
  
  <script>
  import api from "@/api";
  import { Toast } from 'vant';
  import { Dialog } from 'vant';
  const execCopy = (input_id,value)=>{
      const input = document.getElementById(input_id);
      input.value = value;
      // 聚焦
      input.focus();
      // 选择需要复制的文本
      if (input.setSelectionRange) {
          input.setSelectionRange(0, input.value.length);
      } else {
          input.select();
      }
      try {
          const result = document.execCommand('copy');
          if(result){
            Toast.success('复制成功')
          }
      } catch (e) {
        Toast.fail('复制失败，请重试~ 或手动复制')
      }
  }
  export default {
    name: 'Home',
    data(){

      return {
        user:{},
        codes:"dfsdfsd",
        title:"已替换的激活码sfs",
      }
    },
    computed:{
  
    },
    components: {
  
    },
    methods:{
      onClickLeft(){
        this.$router.push({name:'replace_key_index'})
      },
      copyCode(){
        const inputString = this.codes;
        const md5Regex = /[0-9a-f]{32}/gi; // 匹配MD5格式的正则表达式
        const md5Strings = inputString.match(md5Regex); // 匹配字符串中的所有MD5格式的子串
        const finalString = md5Strings.join('\n'); // 使用join方法连接所有MD5字符串，并在之间添加\n分隔符
        execCopy('inputcode',finalString)
      },
      copy(){
        console.log('copy');
        execCopy('inputcode',this.codes)
      },
      handleLog(){
        this.$router.replace({name:'user_center_replace_code_rec_log'})

      }
    },
    mounted(){
        this.user = this.$store.state.user
        this.loading = true
        //获取route参数
        let codes = this.$route.params.codes;
        let total = this.$route.params.total;

        this.codes = codes;
        this.title = total+'个已替换的激活码'
        localStorage.removeItem('replaces_codes')
      }
  }
  </script>
  <style lang="scss" scoped>
  .home-con{
    .cell-title{
      text-align: left;
    }
  
    width: 100vw;
    height: 100vh;
    background-color: #fafafa;
    .head{
      background-color: #2587ee;
      height: 48px;
      color: #fff;
      margin-bottom:15px;
    }
    .van-radio{
      margin-bottom:5px
    }
    .copy-input{
      width: 1px;
      resize: none;
      border: none;
      outline: none;
      user-select: none;
      color: transparent;
      background: transparent;
    }
  }
  </style>